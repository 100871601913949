@import 'primereact/resources/themes/lara-dark-purple/theme.css';

// Tailwind CSS
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'src/pages/Lofanje/EntriesTable/entries_table';

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
  font-family: 'Lato', sans-serif;
}

body {
  background: theme('colors.gray.800');
}