.p-paginator .p-paginator-pages {
  @apply mx-4;
}

.p-paginator .p-paginator-page {
  @apply p-1 mx-1 rounded-lg transition-all duration-200 ease-in-out;
  @apply text-white bg-gray-800 hover:bg-gray-700 border-gray-600 hover:border-gray-400 border-[1px] rounded-full;
}

.p-paginator .p-highlight {
  @apply bg-gray-700 border-gray-400 hover:border-gray-400 text-white font-semibold;
}

.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last {
  @apply p-2 mx-1 text-white bg-gray-800 hover:bg-gray-700 border-gray-600 hover:border-gray-400 border-[1px] rounded-full transition;
}

.p-column-header-content {
  @apply items-end;
}